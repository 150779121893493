.ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
}


/* Snow Theme */

.ql-snow.ql-toolbar {
    display: block;
    background: white;
    border-radius: 0.25rem 0.25rem 0 0;
    box-shadow: 0px 15px 8px rgba(0, 0, 0, 0.26);
}


/* Bubble Theme */

.ql-bubble .ql-editor {
    border: 1px solid #ccc;
    border-radius: 0.5em;
}

.ql-editor {
    min-height: 18em;
    font-size: calc(12px + 1vmin);
}

.themeSwitcher {
    margin-top: 0.5em;
    font-size: small;
}

.editor-contain__title {
    border: none;
    width: 100%;
    overflow-y: auto;
    background-color: #ebebeb;
    outline: none;
    resize: none;
    height: 60px;
    color: #000;
    font-size: 2em;
    font-weight: 800;
    font-family: 'Roboto';
    /* font-family: '"Inconsolata", "Menlo", "Consolas", "Roboto", sans-serif'; */
}

.editor-contain__desc {
    border: none;
    width: 100%;
    overflow-y: auto;
    background-color: #333533;
    outline: none;
    resize: none;
    height: 60px;
    color: white;
    font-size: 1em;
    font-weight: 800;
    font-family: 'Roboto';
}

::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(204, 204, 204);
    opacity: 1;
    /* Firefox */
}

:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgb(204, 204, 204);
}

::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgb(204, 204, 204);
}

.bg-img {
    background-image: url("/public/LandingImage.png");
    background-size: contain;
}

.img-fluid {
    text-align: center;
    color: white;
    margin: auto;
    max-width: 100%;
    height: auto
}

.about-img {
    text-align: center;
}