.videoWrapper {
    float: none;
    clear: both;
    width: 98%;
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;
    margin: 30px auto;
    max-width: 100%;
}

.videoWrapper iframe {
    border-radius: 10px;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
}

.MuiChip-label {
    padding-left: 5px!important;
    padding-right: 6px!important;
}

@media only screen and (max-width: 420px) {
    .videoWrapper iframe {
        height: 200px;
    }
}

@media only screen and (min-width: 425px) {
    .videoWrapper iframe {
        height: 315px;
    }
}

@media only screen and (min-width: 650px) {
    .videoWrapper iframe {
        height: 380px;
    }
}

@media only screen and (min-width: 1024px) {
    .videoWrapper iframe {
        height: 500px;
    }
}

.dlt-btn:hover {
    text-decoration: underline;
}

.not-link:hover {
    text-decoration: underline;
}

.notification-action {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.delete {
    font-size: 14px;
    color: #06c;
    display: block;
    margin: 5px 0px;
}

.home_page_emoji {
    height: auto;
    cursor: pointer;
    min-width: 30px;
    height: 30px;
}

.Three-d-button {
    position: relative;
    color: #000;
    text-decoration: none;
    background-color: #FFFFFF;
    font-family: ‘Trebuchet MS’, ‘Lucida Sans Unicode’, ‘Lucida Grande’, ‘Lucida Sans’, Arial, sans-serif;
    font-weight: 700;
    display: block;
    padding: 4px;
    border-radius: 40px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    box-shadow: 0 8px 0 #a07c45, 0 10px 30px #a07c45;
    -webkit-box-shadow: 0 10px 0 #a07c45, 0 10px 30px #a07c45;
    -moz-box-shadow: 0 10px 0 #a07c45, 0 10px 30px #a07c45;
    margin: 30px auto;
    text-align: center;
    transition: all 0.2s ease;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    -o-transition: all 0.2s ease;
}

.Three-d-button:active {
    position: relative;
    top: 6px;
    -webkit-box-shadow: 0 3px 0 #FFFFFF, 0 9px 25px #FFFFFF;
    -moz-box-shadow: 0 3px 0 #FFFFFF, 0 9px 25px #FFFFFF;
    box-shadow: 0 3px 0 #FFFFFF, 0 9px 25px #FFFFFF;
}

.btn-div {
    text-align: center;
    margin: 30px auto;
}

@keyframes scaling {
    0% {
        transform: perspective(0) scale(1);
    }
    100% {
        transform: perspective(800px) scale(3);
    }
}

@media only screen and (max-width: 768px) {
    .emoji_img {
        min-width: 20px;
        height: 30px;
    }
    .btn-div {
        margin: 0 auto;
    }
}

.error {
    color: #000;
    background: #fff;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", "Fira Sans", Avenir, "Helvetica Neue", "Lucida Grande", sans-serif;
    height: 100vh;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.desc {
    display: inline-block;
    text-align: center;
    line-height: 49px;
    height: 49px;
    vertical-align: middle;
    color: #FFFFFF;
}

.btn-anonymous {
    border: none;
    background: #000;
    color: #FFFFFF;
    padding: 1em 3em;
    line-height: 2em;
    border-radius: 10px;
    box-shadow: 0 10px 18px 6px rgba(0, 0, 0, 0.15);
    text-decoration: none;
    font-size: 1rem;
}

.ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: #fefcfc;
}

.ql-snow.ql-toolbar {
    display: block;
    background: #fff;
    border-radius: 0.25rem 0.25rem 0 0;
    box-shadow: 0 15px 8px rgba(0, 0, 0, 0.26);
}

.ql-bubble .ql-editor {
    border: 1px solid #ccc;
    border-radius: 0.5em;
}

.ql-editor {
    min-height: 18em;
    font-size: calc(12px + 1vmin);
}

.themeSwitcher {
    margin-top: 0.5em;
    font-size: small;
}

.editor-contain__title {
    border: none;
    width: 100%;
    overflow-y: auto;
    background-color: #ebebeb;
    outline: 0;
    resize: none;
    height: 60px;
    color: #000;
    font-size: 2em;
    font-weight: 800;
    font-family: Roboto;
}

.editor-contain__desc {
    border: none;
    width: 100%;
    overflow-y: auto;
    background-color: #333533;
    outline: 0;
    resize: none;
    height: 60px;
    color: #fff;
    font-size: 1em;
    font-weight: 800;
    font-family: Roboto;
}

::placeholder {
    color: #ccc;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #ccc;
}

::-ms-input-placeholder {
    color: #ccc;
}

.bg-img {
    background-image: url(/public/LandingImage.png);
    background-size: contain;
}

.img-fluid {
    text-align: center;
    color: #fff;
    margin: auto;
    max-width: 100%;
    height: auto;
}

.about-img {
    text-align: center;
}

.logo_span {
    display: inline-block;
    color: #000;
    font-size: 13px;
    padding-left: 13px;
    border-left: 1px solid #979797;
    height: 17px;
    font-weight: 400;
    margin-left: 13px;
    position: relative;
    top: -10px;
    line-height: 1;
}

.cover-image {
    max-width: 100%;
    height: auto;
}

.uploaded-image {
    max-width: 100%;
    height: auto;
}

.emoji_img {
    cursor: pointer;
    min-width: 35px;
    height: 35px;
    border-radius: 50%;
    transform: perspective(600px);
}

@media only screen and (max-width: 768px) {
    .logo_span {
        display: none;
    }
}

@media only screen and (max-width: 375px) {
    .emoji_img {
        cursor: pointer;
        min-width: 20px;
        height: 20px;
        border-radius: 50%;
        transform: perspective(600px);
    }
    .home_page_emoji {
        height: auto;
        cursor: pointer;
        min-width: 25px;
        height: 25px;
    }
}

.scrolling-wrapper-flexbox {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
}

.scrolling-wrapper-flexbox .card {
    flex: 0 0 auto;
    margin-right: 3px;
}

.videoCard {
    width: fit-content;
    display: flex;
    margin-right: 10px;
    align-items: center;
}

.more_videoCard {
    align-items: center;
}

.scrolling-block {
    background-color: #d4d4d4fa;
}

.scrolling-wrapper,
.scrolling-wrapper-flexbox {
    height: 130px;
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    -webkit-overflow-scrolling: touch;
}

.scrolling-wrapper::-webkit-scrollbar,
.scrolling-wrapper-flexbox::-webkit-scrollbar {
    display: none;
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    /* 720 / 1280 = 0.5625 */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}